import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKamil as author } from 'data/authors';
import img from 'img/blog/g2-winter-performer.png';

const Content = () => {
  return (
    <Post>
      <p>
        <strong>
          We are happy to announce that we’ve been recognized as G2 High Performer in Session
          Replays for the second time in a row.
        </strong>
      </p>
      <p>
        G2 is one of the most prestigious software review websites. We’ve been awarded the High
        Performer badge again, based on our performance and favorable customer reviews. For example,
        LiveSession has been rated 10/10 when it comes to the ease of use.
      </p>
      <p>
        We are honored to have been recognized again. Thank you for all your reviews and valuable
        feedback! We’re glad that LiveSession meets your needs and we’re doing our best to make it
        even better.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Winter 2019: LiveSession Named a G2 High Performer (Again)',
  url: '/blog/winter-2019-livesession-named-a-g2-high-performer-again/',
  description: `We are happy to announce that we’ve been recognized as G2 High Performer in
  Session Replays for the second time in a row.`,
  author,
  img,
  imgSocial: img,
  date: '2020-01-07',
  category: '',
  group: 'Awards',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
